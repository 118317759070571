.register-form {
    margin: 0 auto;
    width: 50%;
}

.register-text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: blue;
}

.register-form input:not([type=checkbox]) {
    display: block;
    width: 100%;
    height: 35px;
    padding-left: 10px;
    font-size: 18px;
    margin-bottom: 15px;
}